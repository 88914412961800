/**
* Generated automatically at built-time (2025-03-03T13:50:57.584Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "vinkoleskab-black-friday",templateKey: "sites/72-c73eed18-6e1f-47aa-b8fb-c5fb27b8397e"};